<template>
	<div>
		<div>
			<Dialog
				:dialog="dialog"
				:dialog-width="800"
				@close="cancleCreateServiceForm(), $emit('close', true)"
				:content-class="`${softwareTypeDrawer ? 'd-none' : ''}`"
			>
				<template v-slot:title>
					<span v-if="serviceProviderId == 0">Create Service Provider</span>
					<span v-else>Update Service Provider</span>
				</template>

				<template v-slot:body>
					<v-form
						ref="serviceProviderForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="createOrUpdateServiceProvider"
					>
						<div class="pt-2">
							<div class="form-grp-title d-flex">
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto px-0"> Type</v-col>

									<v-col lg="8" class="my-auto py-0">
										<template v-if="serviceProviderId > 0">
											<span for="" class="ml-n4 mr-4 text-capitalize">{{ serviceProvider.type }}</span>
										</template>
										<template v-else>
											<v-radio-group v-model="serviceProvider.type" row hide-details class="mt-0">
												<v-radio value="company"></v-radio>
												<label for="" class="ml-n4 mr-4"> Company</label>
												<v-radio value="client"></v-radio>
												<label for="" class="ml-n4 mr-4"> Client</label>
											</v-radio-group>
										</template>
									</v-col>
								</v-row>
							</div>
							<v-row class="mb-3 px-4">
								<v-col lg="4" cols="12" class="my-auto py-0">
									<label class="input-label required">Provider Name</label>
								</v-col>
								<v-col lg="8" class="my-auto py-0">
									<v-text-field
										hide-details
										outlined
										class="currency-input"
										placeholder="Provider Name"
										v-model="serviceProvider.name"
										:rules="[vrules.required(serviceProvider.name, 'Provider Name')]"
										:class="{
											required: !serviceProvider.name,
										}"
									></v-text-field>
								</v-col>
							</v-row>
							<template v-if="serviceProvider.type == 'company'">
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required">Website</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											class="currency-input"
											placeholder="Website"
											v-model="serviceProvider.website"
											:rules="[vrules.required(serviceProvider.website, 'Website')]"
											:class="{
												required: !serviceProvider.website,
											}"
										></v-text-field>
									</v-col>
								</v-row>

								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Services</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0 d-flex">
										<v-flex class="mr-5">
											<v-checkbox
												v-model="serviceProvider.is_ssl"
												label="SSL"
												hide-details
												class="ma-0"
											></v-checkbox>
										</v-flex>
										<v-flex class="mr-5">
											<v-checkbox
												v-model="serviceProvider.is_domain"
												label="Domain"
												hide-details
												class="ma-0"
											></v-checkbox>
										</v-flex>
										<v-flex class="mr-5">
											<v-checkbox
												v-model="serviceProvider.is_hosting"
												label="Hosting"
												hide-details
												class="ma-0"
											></v-checkbox>
										</v-flex>
									</v-col>
								</v-row>
							</template>
						</div>
					</v-form>
				</template>
				<template v-slot:action>
					<v-btn
						:loading="pageLoading"
						class="white--text mr-2"
						depressed
						color="blue darken-4"
						tile
						:disabled="pageLoading || !formValid"
						v-on:click="createOrUpdateServiceProvider()"
					>
						Save
					</v-btn>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="cancleCreateServiceForm">
						Close
					</v-btn>
				</template>
			</Dialog>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { POST, GET, PUT } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import Dialog from "@/view/components/Dialog";

export default {
	mixins: [MainMixin],
	name: "Create-Contract",
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		serviceProviderId: {
			type: Number,
			default: 0,
		},
	},
	watch: {
		dialog(dialog) {
			if (dialog) {
				if (this.serviceProviderId > 0) {
					this.getServiceProvider();
				}
			}
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			serviceProvider: {
				name: null,
				website: null,
				type: "company",
				is_ssl: true,
				is_domain: true,
				is_hosting: true,
				activated: true,
			},
		};
	},
	components: {
		Dialog,
	},
	methods: {
		cancleCreateServiceForm() {
			this.serviceProvider = {
				name: null,
				website: null,
				type: "company",
				is_ssl: true,
				is_domain: true,
				is_hosting: true,
				activated: true,
			};
			this.$emit("close", true);
		},

		getServiceProvider() {
			this.$store
				.dispatch(GET, {
					url: `service-provider/${this.serviceProviderId}`,
				})
				.then((data) => {
					this.serviceProvider = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		createOrUpdateServiceProvider() {
			const formErrors = this.validateForm(this.$refs["serviceProviderForm"]);
			this.$refs["serviceProviderForm"].validate();
			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				console.log(formErrors);
				return false;
			}
			this.pageLoading = true;
			let requestTYPE = POST;
			let requestURL = "service-provider";
			if (this.serviceProviderId > 0) {
				requestURL = `service-provider/${this.serviceProviderId}`;
				requestTYPE = PUT;
			}
			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: this.serviceProvider,
				})
				.then(() => {
					if (this.serviceProviderId > 0) {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Service Provider updated successfully.",
							},
						]);
					} else {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Service Provider created successfully.",
							},
						]);
					}
					this.$emit("success", true);
					this.cancleCreateServiceForm();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
	mounted() {
		if (this.serviceProviderId > 0) {
			this.getServiceProvider();
		}
	},
};
</script>
