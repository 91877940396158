<template>
	<div class="">
		<template v-if="pageLoading">
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
		<div v-else class="v-data-table bt-table theme--light">
			<div class="v-data-table__wrapper table_height" style="height: calc(100vh - 269px)">
				<table class="w-100">
					<thead>
						<tr>
							<th v-for="(th, th_idx) in theadDataArr" :key="th.key + '_' + th_idx" :width="th.width">
								<template>
									{{ th.name }}
								</template>
							</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="tbody.length">
							<tr v-for="(td, td_idx) in tbody" :key="'ssl_' + td.id + '_' + td_idx">
								<template v-for="(th, th_idx) in theadDataArr">
									<td :key="'server_data_$_' + th.key + '_' + th_idx" @click="routeToDetail(td, th)">
										<template v-if="th.key == 'action'">
											<div class="d-flex justify-space-around align-center">
												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<v-icon
															@click="doAction('update', td)"
															size="20"
															v-on="on"
															v-bind="attrs"
															class="cursor-pointer"
															color="blue"
															>mdi-pencil
														</v-icon>
													</template>
													<span>Edit Server</span>
												</v-tooltip>
											</div>
										</template>

										<template v-if="th.key == 'name'">
											{{ td.name }}
										</template>

										<template v-if="th.key == 'provider_type'">
											<v-chip label outlined color="red darken-4">{{ td.provider_type }}</v-chip>
										</template>

										<template v-if="th.key == 'service_provider'">
											<v-chip label outlined color="green darken-4">{{ td.service_provider }}</v-chip>
										</template>

										<template v-if="th.key == 'cost'">
											<v-chip label outlined color="orange darken-4">{{ formatMoney(td.cost) }}</v-chip>
										</template>

										<template v-if="th.key == 'specifications'">
											<div class="">
												<div class="d-flex justify-space-between">
													<label style="width: 80px; font-weight: 600" class="mb-1">RAM : </label>
													<div v-if="td.Ram">
														<v-chip class="white--text" label color="teal lighten-1" x-small>
															<v-icon small left>mdi-cpu-64-bit</v-icon>
															{{ td.Ram }}&nbsp;GB
														</v-chip>
													</div>
													<div v-else>
														<em class="text-muted">no RAM</em>
													</div>
												</div>
												<div class="d-flex justify-space-between">
													<label style="width: 80px; font-weight: 600" class="">Cores : </label>
													<v-chip class="white--text mb-1 mr-1" label x-small color="purple">
														{{ td.no_of_cores }}
													</v-chip>
												</div>
												<div class="d-flex justify-space-between mr-1">
													<label style="width: 80px; font-weight: 600" class="mb-1">Database : </label>
													<div class="d-flex align-center">
														<v-chip
															color="red darken-4"
															class="white--text"
															x-small
															label
															v-if="td.no_of_database"
														>
															<v-icon small left>mdi-database</v-icon>
															{{ td.no_of_database }}
														</v-chip>
														<em class="text-muted" v-else>no Databases</em>
													</div>
												</div>
												<div class="d-flex justify-space-between mr-1">
													<label style="width: 80px; font-weight: 600" class="mb-1">Storage : </label>
													<div class="d-flex align-center">
														<v-chip color="yellow darken-4" class="white--text" x-small label v-if="td.storage">
															<v-icon small left>mdi-sd</v-icon>
															{{ td.storage }}
														</v-chip>
														<em class="text-muted" v-else>no storage</em>
													</div>
												</div>
											</div>
										</template>

										<template v-if="th.key == 'date'">
											<div class="">
												<div class="d-flex justify-space-between">
													<label style="width: 80px; font-weight: 600" class="mb-1">Activation : </label>
													<div v-if="td.activation_date">
														<v-chip class="" outlined label color="teal lighten-1" x-small>
															<v-icon small left>mdi-calendar</v-icon>
															{{ formatDate(td.activation_date) }}
														</v-chip>
													</div>
													<div v-else>
														<em class="text-muted">no Activation Date</em>
													</div>
												</div>
												<div class="d-flex justify-space-between">
													<label style="width: 80px; font-weight: 600" class="">Renewal : </label>
													<div v-if="td.renewal_date">
														<v-chip class="mb-1" outlined label x-small color="purple">
															<v-icon small left>mdi-calendar</v-icon>
															{{ formatDate(td.renewal_date) }}
														</v-chip>
													</div>
													<div v-else>
														<em class="text-muted">no Renewal Date</em>
													</div>
												</div>

												<div class="d-flex justify-space-between">
													<label style="width: 80px; font-weight: 600" class="">Renewal Basis : </label>
													<div v-if="td.renewal_basis">
														<v-chip class="mb-1" outlined label x-small color="red darken-4">
															<v-icon small left>mdi-update</v-icon>
															{{ td.renewal_basis[0].toUpperCase() + td.renewal_basis.slice(1) }}
														</v-chip>
													</div>
													<div v-else>
														<em class="text-muted">no RAM</em>
													</div>
												</div>
											</div>
										</template>

										<template v-if="th.key == 'added_at'">
											<div class="">
												<div class="d-flex justify-space-between">
													<label style="width: 80px; font-weight: 600" class="mb-1">Added by : </label>
													<div v-if="td.added_by">
														<v-chip class="" outlined label color="teal lighten-1" x-small>
															<v-icon small left>mdi-account</v-icon>
															{{ td.added_by }}
														</v-chip>
													</div>
													<div v-else>
														<em class="text-muted">no Activation Date</em>
													</div>
												</div>
												<div class="d-flex justify-space-between">
													<label style="width: 80px; font-weight: 600" class="">Added at : </label>
													<div v-if="td.added_at">
														<v-chip class="mb-1" outlined label x-small color="purple">
															<v-icon small left>mdi-calendar</v-icon>
															{{ formatDate(td.added_at) }}
														</v-chip>
													</div>
													<div v-else>
														<em class="text-muted">no RAM</em>
													</div>
												</div>
											</div>
										</template>
									</td>
								</template>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td :colspan="8">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no projects at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
		<v-row class="my-2" v-if="tbody.length">
			<v-col md="6" class="">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					v-model="page"
					:length="totalPage"
					total-visible="9"
					class="listing-pagination"
					@input="updatePagination"
				></v-pagination>
			</v-col>
		</v-row>
	</div>
	<!-- </v-sheet> -->
</template>
<script>
import MomentJS from "moment-timezone";
import { mapGetters } from "vuex";
import { SET_CURRENT_PAGE, GET_LISTING } from "@/core/services/store/listing.module";
import HeightMixin from "@/core/mixins/height.mixin";
import objectPath from "object-path";
import { filter } from "lodash";

export default {
	name: "SSL",
	mixins: [HeightMixin],
	props: {
		internalType: {
			type: String,
			default: null,
		},
		internalValue: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			skipBottomHeight: 56,
			pageTitle: "SSLs",
			page: 1,
			status: null,
			sslList: [],
			project_status: {
				1: { title: "Draft", color: "cyan" },
				2: { title: "Completed", color: "green" },
				3: { title: "Renewed", color: "orange" },
				4: { title: "Cancelled", color: "red" },
			},
			pageLoading: true,
		};
	},
	methods: {
		getListingData() {
			this.pageLoading = true;
			const filter = new Object({
				tz: new Date().getTime(),
				page: this.currentPage ? this.currentPage : null,
			});
			if (this.internalType && this.internalValue) {
				filter[this.internalType] = this.internalValue;
			}

			this.$store
				.dispatch(GET_LISTING, { url: "server-detail", filter })
				.then(() => {
					setTimeout(() => {
						this.calculateHeight();
					}, 500);
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getValue(object, path) {
			return objectPath.get(object, path);
		},
		routeToDetail(td, th) {
			if (th.checkbox || th.key == "action") {
				return false;
			} else {
				let filterQuery = this.$route.query;
				let id = td.id;
				this.$router.push({
					name: "ssl-detail",
					params: { id },
					query: { ...filterQuery, t: new Date().getTime() },
				});
			}
		},
		date_difference(start, end) {
			let start_Date = MomentJS(start);
			let end_Date = MomentJS(end);
			let totalDays = end_Date.diff(start_Date, "days");
			return totalDays;
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.getListingData();
			});
		},
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"count",
			"currentPage",
			"currentUser",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
		]),
		theadDataArr() {
			let arr = filter(
				this.thead,
				(row) =>
					row.key != "id" &&
					row.key != "action" &&
					row.key != "last_update" &&
					row.key != "service_provider"
			);
			return arr;
		},
	},
	components: {},
	mounted() {
		this.getListingData();
	},
};
</script>
