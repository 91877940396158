<template>
	<div class="">
		<template v-if="pageLoading">
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
		<div v-else class="v-data-table bt-table theme--light">
			<div class="v-data-table__wrapper table_height" style="height: calc(100vh - 269px)">
				<table class="w-100">
					<thead>
						<tr>
							<th v-for="(th, th_idx) in theadDataArr" :key="th.key + '_' + th_idx" :width="th.width">
								<template>
									{{ th.name }}
								</template>
							</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="tbody.length">
							<tr v-for="(td, td_idx) in tbody" :key="'ssl_' + td.id + '_' + td_idx">
								<template v-for="(th, th_idx) in theadDataArr">
									<td
										:key="'ssl_data_$_' + th.key + '_' + th_idx"
										:width="th.width"
										@click="routeToDetail(th, td.id)"
									>
										<template v-if="th.key == 'entity'">
											<div class="d-flex justify-center align-center">
												<ImageTemplate
													v-if="td.company_type == 'genic-solution'"
													style="max-width: 35px; height: 35px; box-shadow: 0px 0px 1px 0px #888"
													src="media/logos/genic_solution.png"
													circle
													class="mr-2"
												></ImageTemplate>
												<ImageTemplate
													v-if="td.company_type == 'bthrust'"
													style="max-width: 35px; height: 35px; box-shadow: 0px 0px 1px 0px #888"
													src="media/logos/bthurst.png"
													circle
													class="mr-2"
												></ImageTemplate>
											</div>
										</template>

										<template v-if="th.key == 'project_details'">
											<div class="">
												<div class="d-flex justify-space-between">
													<label style="width: 80px; font-weight: 600" class="mb-1">Domain : </label>
													<div v-if="td.domain_name">
														<v-chip class="white--text" label color="teal lighten-1" x-small>
															{{ td.domain_name }}
														</v-chip>
													</div>
													<div v-else>
														<em class="text-muted">no project</em>
													</div>
												</div>
												<div class="d-flex justify-space-between">
													<label style="width: 80px; font-weight: 600" class="">Status : </label>
													<v-chip
														class="mb-1 mr-1"
														label
														v-if="getValue(project_status, `${getValue(td, 'projectData.status')}.title`)"
														x-small
														:color="`${getValue(
															project_status,
															`${getValue(td, 'projectData.status')}.color`
														)} white--text`"
													>
														{{ getValue(project_status, `${getValue(td, "projectData.status")}.title`) }}
													</v-chip>
													<em v-else class="text-muted">no status</em>
												</div>
												<div class="d-flex justify-space-between mr-1">
													<label style="width: 80px; font-weight: 600" class="mb-1">Sales : </label>
													<div class="d-flex align-center">
														<ImageTemplate
															v-if="getValue(td, 'sales_person.profile_img')"
															style="
																margin-right: 8px;
																max-width: 30px;
																height: 30px;
																box-shadow: 0px 0px 1px 0px #888;
															"
															:src="getValue(td, 'sales_person.profile_img')"
															circle
														></ImageTemplate>

														<div v-if="getValue(td, 'sales_person.display_name')">
															{{ getValue(td, "sales_person.display_name") }}
														</div>
														<em v-else>no sales owener</em>
													</div>
												</div>
											</div>
										</template>
										<template v-if="th.key == 'billing'">
											<div class="text-center">
												<v-chip
													class="white--text"
													label
													:color="td.type == 'bthurst' ? 'orange' : 'teal lighten-1'"
													>{{ td.type }}
												</v-chip>
											</div>
										</template>

										<template v-if="th.key == 'date'">
											<div class="position-relative" style="display: grid; place-items: center">
												<div>
													<label style="width: 60px; font-weight: 600" class="mb-1">Start :</label>
													<v-chip
														v-if="td.start_date"
														label
														color="orange darken-1 white--text"
														outlined
														text-color=""
													>
														<span class="fw-500"> {{ formatDate(td.start_date) }}</span>
													</v-chip>
													<em class="text-muted" v-else>no start date</em>
												</div>
												<div class="mt-1">
													<label style="width: 60px; font-weight: 600" class="mb-1">End :</label>
													<v-chip v-if="td.end_date" label color="green white--text" outlined text-color="">
														<span class="fw-500"> {{ formatDate(td.end_date) }}</span>
													</v-chip>
													<em class="text-muted" v-else>no end date</em>
												</div>

												<div
													v-if="date_difference(formatDate_Raw(new Date()), td.end_date) < 0"
													class="tilted_watermark mb-0 red--text px-2 py-1 position-absolute rounded text-center"
												>
													Expired
												</div>
											</div>
										</template>

										<template v-if="th.key == 'cost/sales_price'">
											<div class="d-flex">
												<label style="width: 80px; font-weight: 600" class="mb-1">Cost Price : </label>
												<v-chip label color="green white--text" outlined text-color="">
													<span class="fw-500"> {{ formatMoney(td?.cost) }}</span>
												</v-chip>
											</div>
											<div class="d-flex">
												<label style="width: 80px; font-weight: 600" class="mb-1">Sale Price : </label>
												<v-chip
													class="mt-1"
													label
													color="deep-orange lighten-1 white--text"
													outlined
													text-color=""
												>
													<span class="fw-500"> {{ formatMoney(td?.sales_price) }}</span>
												</v-chip>
											</div>
										</template>

										<template v-if="th.key == 'remark'">
											<div
												class="line-clamp-1"
												style="
													max-width: 250px;
													text-wrap: initial;
													display: -webkit-box;
													-webkit-line-clamp: 2;
													-webkit-box-orient: vertical;
													overflow: hidden;
												"
											>
												<ShowTextAreaValue :object="td" object-key="remark" label="remark"> </ShowTextAreaValue>
											</div>
										</template>

										<template v-if="th.key == 'status'">
											<v-chip
												label
												v-if="getValue(td, 'ssl_status')"
												class="white--text"
												:color="td.ssl_status == 'active' ? 'green' : 'red'"
											>
												{{
													getValue(td, "ssl_status") &&
													td.ssl_status.charAt(0).toUpperCase() + td.ssl_status.slice(1)
												}}
											</v-chip>
										</template>

										<template v-if="th.key == 'last_update'">
											<Chip class="mr-1" outlined :text="td.updated_at" textColor="green" color="green"></Chip>
										</template>
									</td>
								</template>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td :colspan="8">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no projects at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
		<v-row class="my-2" v-if="tbody.length">
			<v-col md="6" class="">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					v-model="page"
					:length="totalPage"
					total-visible="9"
					class="listing-pagination"
					@input="updatePagination"
				></v-pagination>
			</v-col>
		</v-row>
	</div>
	<!-- </v-sheet> -->
</template>
<script>
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import MomentJS from "moment-timezone";
import { mapGetters } from "vuex";
import { SET_CURRENT_PAGE, GET_LISTING } from "@/core/services/store/listing.module";
import HeightMixin from "@/core/mixins/height.mixin";
import objectPath from "object-path";
import { filter } from "lodash";

export default {
	name: "SSL",
	mixins: [HeightMixin],
	props: {
		internalType: {
			type: String,
			default: null,
		},
		internalValue: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			skipBottomHeight: 56,
			pageTitle: "SSLs",
			page: 1,
			status: null,
			sslList: [],
			project_status: {
				1: { title: "Draft", color: "cyan" },
				2: { title: "Completed", color: "green" },
				3: { title: "Renewed", color: "orange" },
				4: { title: "Cancelled", color: "red" },
			},
			pageLoading: true,
		};
	},
	methods: {
		getListingData() {
			this.pageLoading = true;
			const filter = new Object({
				tz: new Date().getTime(),
				page: this.currentPage ? this.currentPage : null,
			});
			if (this.internalType && this.internalValue) {
				filter[this.internalType] = this.internalValue;
			}

			this.$store
				.dispatch(GET_LISTING, { url: "ssls", filter })
				.then(() => {
					setTimeout(() => {
						this.calculateHeight();
					}, 500);
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getValue(object, path) {
			return objectPath.get(object, path);
		},
		routeToDetail(th, id) {
			if (th.checkbox || th.key == "action") {
				return false;
			} else {
				let filterQuery = this.$route.query;
				this.$router.push({
					name: "ssl-detail",
					params: { id },
					query: { ...filterQuery, t: new Date().getTime() },
				});
			}
		},
		date_difference(start, end) {
			let start_Date = MomentJS(start);
			let end_Date = MomentJS(end);
			let totalDays = end_Date.diff(start_Date, "days");
			return totalDays;
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.getListingData();
			});
		},
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"count",
			"currentPage",
			"currentUser",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
		]),
		theadDataArr() {
			let arr = filter(
				this.thead,
				(row) =>
					row.key != "id" &&
					row.key != "action" &&
					row.key != "last_update" &&
					row.key != "service_provider"
			);
			return arr;
		},
	},
	components: {
		ImageTemplate,
		Chip,
		ShowTextAreaValue,
	},
	mounted() {
		this.getListingData();
	},
};
</script>
